import React, { useRef, useEffect, useState } from "react";
import Footer from "../../NewComponents/Common/Footer/Footer";
import FAQ from "../../NewComponents/Page-3/FAQ/FAQ";
import FoodOptions from "../../NewComponents/Page-3/FoodOptions/FoodOptions";
import GalleryAmenity from "../../NewComponents/Page-3/Gallery and Amenities/Gallery-amenities";
import Layout from "../../NewComponents/Page-3/Layout/Layout";
import Navigation from "../../NewComponents/Page-3/Navigation/Navigation";
import NearbyPlaces from "../../NewComponents/Page-3/Nearby Places/Nearby";
import Review from "../../NewComponents/Page-3/Reviews/Review";
import RulesAndPolicies from "../../NewComponents/Page-3/RulesAndPolicies/RulesAndPolicies";
import SimilarVillasCrousel from "../../NewComponents/Page-3/Similar Villas/Similar Villas";
import Title from "../../NewComponents/Page-3/Title and Address/Title";
import Header from "../../NewComponents/Common/Headers/Header";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ClipboardJS from "clipboard";
import HeaderMobile from "../../NewComponents/Common/Headers/Header-mobile";
import FloatingButtons from "../../NewComponents/Common/ContactButton/FloatingButtons";
const Section = ({ id, children }) => {
  return <div id={id}>{children}</div>;
};

const VillaDetailPage = () => {
  const { villaId: urlVillaId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const Location = useLocation();
  const formData = Location.search ? JSON.parse(decodeURIComponent(Location.search.replace('?formData=', ''))) : null;
  //console.log(formData,"formdata")
  
  const linkRef = useRef(null);
  const [location, setLocation] = useState(
    formData?.location ? formData.location : ""
  );
  const [adults, setAdults] = useState(formData?.adults ? formData.adults : 2);
  const [children, setChildren] = useState(
    formData?.children ? formData.children : 0
  );
  const [startDate, setStartDate] = useState(
    formData?.startDate ? new Date(formData.startDate) : ""
  );
  const [endDate, setEndDate] = useState(
    formData?.endDate ? new Date(formData.endDate) : ""
  );

  const [villaData, setVillaData] = useState(null);
  const [totalPrice, setTotalPrice] = useState();
  const [availability, setAvailability] = useState(true);

  const handleSearch = async () => {
    const searchVillaId = formData?.villaId ? formData.villaId : urlVillaId;

    if (searchVillaId) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_AWS}/getvillabyid?property_id=${searchVillaId}`
      );

      setVillaData(res.data.data[0]);
      console.log(res.data.data[0], "dataaa");
    }
  };
console.log("asdsa")
  useEffect(() => {
    handleSearch();
  }, []);

  useEffect(() => {
    if (villaData && startDate && endDate) {
      let priceSum = 0;
      let flag = true;

      for (
        let d = new Date(startDate);
        d < endDate;
        d.setDate(d.getDate() + 1)
      ) {
        let tempDate = d.toISOString().slice(0, 10);
        if (villaData.availability_per_date[tempDate] == 0) flag = false;
        priceSum += parseInt(villaData.price_per_date[tempDate]);
      }
      setTotalPrice(priceSum);
      if (!priceSum || priceSum === 0) flag = false;
      setAvailability(flag);
      console.log(flag,"flag1");
      //console.log(priceSum, "Total wala");
    }
  }, [villaData, startDate, endDate]);

  const handleSearchClick = () => {
    const formData = {
      location: location,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
    };
    const hyphenlocation = location.replace(" ", "-");
    navigate(`/search/${hyphenlocation}`, { state: formData });
  };
  // const handleShareClick = () => {
  //   // Copy the sharable link and message to the clipboard
  //   const link = `${window.location.origin}/villa/${
  //     villaData.property_ptr_id
  //   }/${villaData.property_name.replace(/\s+/g, "-")}`;

  //   const clipboard = new ClipboardJS(linkRef.current, {
  //     text: () => link,
  //   });

  //   clipboard.on("success", () => {
  //     // Show the modal popup when the link is copied
  //     setShowModal(true);
  //     clipboard.destroy();
  //   });

  //   clipboard.on("error", (err) => {
  //     console.error("Could not copy text: ", err);
  //     clipboard.destroy();
  //   });
  // };

  return villaData ? (
    <div>
      <Header
        location={location}
        setLocation={setLocation}
        setAdults={setAdults}
        adults={adults}
        children={children}
        setChildren={setChildren}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleSearchClick={handleSearchClick}
        check={true}
      />
      <HeaderMobile
        location={location}
        setLocation={setLocation}
        setAdults={setAdults}
        adults={adults}
        children={children}
        setChildren={setChildren}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleSearchClick={handleSearchClick}
        check={true}
      />
      <div>
        <Title villaData={villaData} />
        <Navigation />
        <Section key={0} id="Amenities">
          <GalleryAmenity villaData={villaData} />
        </Section>
        <Section key={1} id="Layout">
          <div className="text-[28px] lg:text-[50px] text-center font-bold my-4 lg:m-8my-4 lg:m-8 font-jt">
            Layout
          </div>
          <Layout
            villaData={villaData}
            setAdults={setAdults}
            adults={adults}
            children={children}
            setChildren={setChildren}
            startDate={startDate}
            endDate={endDate}
          />
        </Section>
        <Section key={2} id="Food">
          <FoodOptions villaData={villaData} />
        </Section>
        <Section key={3} id="Places Nearby">
          <Section key={3} id="Nearby">
            <NearbyPlaces villaData={villaData} />
          </Section>
        </Section>
        <Section key={4} id="More">
          <Section key={4} id="Rules & Policies">
            <RulesAndPolicies villaData={villaData} />
          </Section>
          <Section key={5} id="Reviews">
            <Review villaData={villaData} />
          </Section>
          <Section key={6} id="FAQs">
            <FAQ villaData={villaData} />
          </Section>
          <Section key={7} id="Similar Villas">
            <SimilarVillasCrousel
              villaData={villaData}
              location={location}
              adults={adults}
              children={children}
              startDate={startDate}
              endDate={endDate}
              formData={formData}
            />
          </Section>
        </Section>
        <FloatingButtons/>
        <Footer />
        
      </div>
      <div className="font-jt hidden  sticky  z-[400000] bottom-0">
        <div className="bg-[#f2e9f5] flex flex-col border-[1px] border-[#661183] rounded-t-3xl text-black ">
          <div className="flex flex-row w-full justify-between">
            <div>
              <div className="text-base ml-4 mt-2">
                {new Date(startDate).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                })}{" "}
                -
                {new Date(endDate).toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                })}
              </div>
              <div className="text-sm ml-4">
                {adults} Adults & {children} Children
              </div>
            </div>
            <div className="text-4xl  mt-2 mr-4">₹22,012</div>
          </div>
          <div className="w-[100vw]  flex flex-row p-2 pb-4 justify-between items-center">
            <button className="bg-black rounded-full font-semibold text-white shadow-lg text-2xl  w-[48%] p-1  ">
              Book Now
            </button>
            <button className="bg-white rounded-full font-semibold text-black shadow-lg text-2xl  w-[48%] p-1  ">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
  <img
    src="https://cdn.dribbble.com/users/563824/screenshots/3633228/media/d876c7712d969c0656302b16b16af2cc.gif"
    alt=""
  />
</div>

  );
};
export default VillaDetailPage;
