import { useEffect, useState } from "react";
import MainSection from "../../NewComponents/Page-1/Main-Section/MainSection";
import Login_Signup from "../login-signup/login-signup";
import PopularDestination from "../../NewComponents/Page-1/Popular-Destinations/PopularDestination";
import Overview from "../../NewComponents/Page-1/Overview/Overview";
import HostProperty from "../../NewComponents/Page-1/Host-Property/HostProperty";
import Footer from "../../NewComponents/Common/Footer/Footer";
import FloatingButtons from "../../NewComponents/Common/ContactButton/FloatingButtons";

const HomePage = () => {
  return (
    <>
      <div className="">
        <MainSection />
      </div>
      <div className="my-[20px]">
        <PopularDestination />
      </div>
      <div className="my-[100px]">
        <Overview />
      </div>
      <div className="">
        <HostProperty />
      </div>
      <div>
        <FloatingButtons/>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
