import React from 'react';

const AboutUs = () => (
  <div className="w-full h-full bg-white p-8 font-jt text-gray-800">
    {/* Header Section */}
    <h1 className="text-4xl font-bold mb-6 text-center text-black">About Us</h1>
    
    {/* Introduction Section */}
    <p className="mb-4 leading-relaxed text-lg">
      Welcome to India’s newest and largest villa aggregator service provider—a platform designed to redefine the way you experience luxury living and travel. 
      Founded by a passionate group of IITians, we combine innovation, expertise, and an unwavering commitment to excellence to bring you the finest selection of 
      premium villas across the country.
    </p>
    <p className="mb-8 leading-relaxed text-lg">
      Our mission is simple: to make luxury more accessible and experiences unforgettable. Whether you're planning a serene getaway in the hills, 
      a beachfront escape, or a private space to celebrate life’s milestones, we’ve got you covered.
    </p>
    
    {/* Unique Features Section */}
    <h2 className="text-3xl font-bold mb-6 text-black">What Sets Us Apart?</h2>
    <ol className="list-decimal list-inside space-y-4 pl-4">
      <li>
        <b className="text-lg font-semibold">Unmatched Selection:</b> 
        <span className="text-lg"> We bring you the largest curated collection of villas, each handpicked for its unique charm, comfort, and quality.</span>
      </li>
      <li>
        <b className="text-lg font-semibold">Tech-Driven Convenience:</b> 
        <span className="text-lg"> Leveraging cutting-edge technology, we ensure a seamless booking experience with transparent pricing, verified listings, and personalized recommendations.</span>
      </li>
      <li>
        <b className="text-lg font-semibold">Customer-Centric Approach:</b> 
        <span className="text-lg"> Your happiness is our priority. From booking to checkout, we’re here to make every step effortless and enjoyable.</span>
      </li>
      <li>
        <b className="text-lg font-semibold">IITian Expertise:</b> 
        <span className="text-lg"> With a background in problem-solving and innovation, our founders are committed to revolutionizing the villa rental landscape with scalable solutions and exceptional service.</span>
      </li>
    </ol>
    
    {/* Closing Section */}
    <p className="mt-8 leading-relaxed text-lg">
      Join us on this journey as we redefine luxury stays, one villa at a time. Your dream escape is just a click away!
    </p>
    <p className="mt-4 leading-relaxed text-lg font-semibold">
      Explore. Indulge. Relax. <br />
      With us, every stay is an extraordinary experience.
    </p>
  </div>
);

export default AboutUs;
