import { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Header from "../../NewComponents/Common/Headers/Header";
import SubHeader from "../../NewComponents/Page-2/Sub-Header/SubHeader";
import Sidebar from "../../NewComponents/Page-2/Sidebar/Sidebar";
import HotelCard from "../../NewComponents/Page-2/Hotel card/Hotel-card";
import Footer from "../../NewComponents/Common/Footer/Footer";
import { ColorRing, Grid, LineWave } from "react-loader-spinner";
import axios from "axios";
import Pagination from "../../components/Pagination/Pagination";
import SubHeaderMobile from "../../NewComponents/Page-2/Sub-Header/SubHeader-mobile";
import HeaderMobile from "../../NewComponents/Common/Headers/Header-mobile";
import FloatingButtons from "../../NewComponents/Common/ContactButton/FloatingButtons";

const hotel = {
  imageUrl:
    "https://th.bing.com/th/id/OIP.yBzy15rlMLEfll12IeotogHaE8?pid=ImgDet&rs=1",
  rating: 4,
  name: "Hotel Example",
  address: "123 Example Street, City, Country",
  area: 1000,
  bhk: 3,
  bedrooms: 3,
  bathrooms: 2,
  price: "$200",
};

const tempAmenities = [
  {
    id: 7,
    icon: {
      id: 7417,
      active_status: false,
      created_at: "2022-02-16T15:28:52.948530+05:30",
      updated_at: "2022-02-16T15:28:52.948550+05:30",
      name: null,
      type: "IMAGE",
      file: "https://icons8.com/icon/102741/wait",
      tags: [],
    },
    name: "Please Wait...",
    is_secondary: false,
    is_view: false,
  },
];

const VillaSearchPage = () => {



  const [mobileshow, setmobileshow] = useState(false);
  useEffect(() => {
    function handleResize() {
      let width = window.innerWidth;

      if (width < 1000) {
        setmobileshow(true);
      }
    }

    // Initial call to handleResize to set the image based on the initial window size
    handleResize();

    // Set up event listener for resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);






  const navigate = useNavigate();
  const Location = useLocation();
  const formData = Location.state;

  //console.log("YE RAHA DATA", formData);
  const { city: cityParam } = useParams();
  // alert(cityParam)
  const newcity = cityParam.replace("-", " ");
  const [villasData, setVillasData] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [villalist, setVillalist] = useState(null);
  const [landmarklist, setlandmarklist] = useState(null);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(8);
  const [pageQuery, setPageQuery] = useState("&page_number=1");
  const [queryPrefix, setQueryPrefix] = useState(
    `${process.env.REACT_APP_API_AWS}/getAllVillas?`
  );

  const [locationQuery, setLocationQuery] = useState("");

  useEffect(() => {
    const city = formData?.location || cityParam || "Lonavala";
    const adults = formData?.adults || 2;
    const children = formData?.children || 0;

    setLocationQuery(`city=${city}&adults=${adults}&children=${children}`);
  }, [cityParam, formData]);
  const [finalQuery, setFinalQuery] = useState("");
  const [SubHeaderQuery, setSubHeaderQuery] = useState("");

  // states for header
  const [location, setLocation] = useState(
    formData ? formData.location : newcity
  );
  const [adults, setAdults] = useState(formData ? formData.adults : 2);
  const [children, setChildren] = useState(formData ? formData.children : 0);
  const currentDate = new Date();
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const nextDayTimestamp = currentDate.getTime() + oneDayInMilliseconds;
  const nextDayDate = new Date(nextDayTimestamp);
  const [startDate, setStartDate] = useState(
    formData?.startDate ? formData.startDate : currentDate
  );
  const [endDate, setEndDate] = useState(formData?.startDate ? formData.endDate : nextDayDate);
  const [showModal, setShowModal] = useState(false);
  const handleAmenities = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_AWS}/getAmenitiesCity?${locationQuery}`
    );
    window.scrollTo(0, 0);
    //console.log("AMEN", res.data);
    setAmenities(res.data.data);
    setlandmarklist(res.data.landmark);
    setVillalist(res.data.villas);
    // handleSearch();
  };
  useEffect(() => {
    handleAmenities();
  }, [locationQuery]);

  useEffect(() => {
    if (query !== "") {
      let temp =
        queryPrefix + locationQuery + query + SubHeaderQuery + pageQuery;
      setFinalQuery(temp);
      //console.log("Final query updated!");
      // //console.log("LOCATION - ", locationQuery);
      // //console.log("QUERY - ", query);
      //console.log("FINAL - ", temp);
    }
  }, [query, locationQuery, SubHeaderQuery, pageQuery]);

  const handleSearch = async () => {
    setVillasData([])
    // let temp = queryPrefix + locationQuery + query;
    // setFinalQuery(temp);
    //console.log(finalQuery, "sed");
    const res = await axios.get(finalQuery);
    //console.log(res.data.data, "noice");
    setVillasData(res.data.data);
    window.scrollTo(0, 0);
    // setAmenities(res.data.data2);
    // //console.log(locationQuery,"asds")
    //console.log(formData);
  };

  useEffect(() => {
    if (finalQuery !== "") {
      handleSearch();
    }
  }, [finalQuery]);

  const handleCardClick = (villaId, villaName) => {
    const formData2 = {
      villaId: villaId,
      villaName: villaName,
      location: location,
      startDate: startDate,
      endDate: endDate,
      adults: adults,
      children: children,
    };
    const hyphenatedVillaName = villaName.replace(/\s+/g, "-");
    const url = `/villa/${villaId}/${hyphenatedVillaName}?formData=${encodeURIComponent(JSON.stringify(formData2))}`;

    // Open the link in a new tab
    const newTab = window.open(url, '_blank');
    if (!newTab) {
      // Handle cases where the popup blocker prevents opening the new tab
      // You might want to show a message to the user in this case.
      navigate(url); // Fallback to navigating in the same tab
    }
  };

  const handleSearchClick = (a = false) => {
    let destination = location ? location : newcity;
    let diffTime;
    if (endDate instanceof Date && startDate instanceof Date){
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      //console.log(endDate,startDate,diffTime,"datecheck")
    }
    else diffTime = 0;
    const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' })




    let tempStartDate = startDate instanceof Date ? (startDate.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' }).slice(0, 10)) : null;


    if (startDate instanceof Date && tempStartDate) {
      let dateStr = tempStartDate;
      let [month, day, year] = dateStr.split("/");
      //console.log(month, day, year, "123a");
      tempStartDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    let newEndDate = null;
    if (endDate instanceof Date) {
      newEndDate = new Date(endDate.getTime());
      newEndDate.setDate(newEndDate.getDate() - 1);
    }
    let tempEndDate = newEndDate?.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' }).slice(0, 10);
    if (newEndDate instanceof Date && tempEndDate) {
      let dateStr = tempEndDate;
      let [month, day, year] = dateStr.split("/");
      tempEndDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
    //console.log(tempStartDate, tempEndDate, "123a");
    let temp = `city=${destination}&adults=${adults}&children=${children}&startDate=${tempStartDate}&endDate=${tempEndDate}&days=${days}`;
    setLocationQuery(temp);
    window.history.pushState(
      null,
      "",
      `/search/${destination.replace(" ", "-")}`
    );
    // if (a) navigate(`/search/${destination.replace(' ','-')}`,{ replace: true });
  };

  useEffect(() => {
    handleSearchClick();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
    <FloatingButtons/>
      {amenities.length > 0 && villalist != null && landmarklist !== null ? (
        <div className="h-full ">
          {!mobileshow ? (
            <Header
              location={location}
              setLocation={setLocation}
              setAdults={setAdults}
              adults={adults}
              children={children}
              setChildren={setChildren}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              handleSearchClick={handleSearchClick}
              check={true}
            />
          ) : (
            <HeaderMobile
              location={location}
              setLocation={setLocation}
              setAdults={setAdults}
              adults={adults}
              children={children}
              setChildren={setChildren}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              handleSearchClick={handleSearchClick}
              check={true}
            />
          )}

          {!mobileshow && (
            <SubHeader
              setSubHeaderQuery={setSubHeaderQuery}
              villalist={villalist}
              landmarklist={landmarklist}
              amenities={amenities}
              query={query}
              setQuery={setQuery}
            />
          )}
          <div className="grid grid-cols-1 lg:grid-cols-[repeat(13,minmax(0,1fr))] mr-0 md:mr-4">
  <div className="col-span-1 lg:col-span-3 hidden lg:block">
    <Sidebar amenities={amenities} query={query} setQuery={setQuery} />
  </div>
  <div className="col-span-1 lg:col-span-10">
    {villasData.length > 0 ? (
      <div>
        {villasData.map((villadata) => (
          <div key={villadata.id}>
            <HotelCard
              hotel={hotel}
              city={cityParam}
              villadata={villadata}
              amenitiesAll={amenities}
              handleCardClick={handleCardClick}
              startDate={startDate}
              endDate={endDate}
              adults={adults}
              children={children}
              citylocation={location}
            />
          </div>
        ))}
        <Pagination
          totalPosts={80}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageQuery={setPageQuery}
        />
        {window.scrollTo(0, 0)}
      </div>
    ) : (
      <div className="w-full mt-[50vh] flex justify-center mb-10">
        <Grid
          height="80"
          width="80"
          color="#b59c9c"
          ariaLabel="grid-loading"
          radius="12.5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    )}
  </div>
</div>
          <div>{/* <Footer /> */}</div>
          {mobileshow && (
            <SubHeaderMobile
              setSubHeaderQuery={setSubHeaderQuery}
              villalist={villalist}
              landmarklist={landmarklist}
              amenities={amenities}
              query={query}
              setQuery={setQuery}
            />
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <img
            src="https://cdn.dribbble.com/users/563824/screenshots/3633228/media/d876c7712d969c0656302b16b16af2cc.gif"
            alt=""
          />
        </div>
      )}
    </>
  );
};

export default VillaSearchPage;
