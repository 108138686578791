import { Link } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState("");
  const handleClick = (city) => {
    const formattedCity = city.replace(/\s+/g, "-");
    navigate(`/search/${formattedCity}`);
  };
  return (
    <div className="relative  mt-[100px]">
      <div className="bg-white rounded-full h-[30px] md:h-[45px] lg:h-[60px]   "></div>
      <div className="font-jt bg-[#2E2E3C] md:bg-black opacity-90 py-6 lg:py-8 absolute -z-10 top-[14px] lg:top-[25px] w-full ">
        <div className="text-white md:grid grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-16 px-3 md:px-20 py-2 md:py-6 lg:py-8 hidden ">
          <div className="space-y-4 lg:px-5 justify-center">
            <h1 className="mb-3 font-semibold text-center text-[18px]   ">
              ABOUT A2Z
            </h1>
            <h1 className=" text-[14px] lg:text-[14px] lg:leading-loose font-light ">
              A2Z Villaz is a company founded to provide experiences to travelers,
              which they can never forget. We want to work towards a painless
              traveling experience within India.
            </h1>
          </div>
          <div className="space-y-4 lg:px-5  justify-center">
            <h1 className="mb-3 font-semibold text-[18px] text-center">
              NAVIGATIONAL LINKS
            </h1>
            <div className="text-[12px] lg:text-[15px]  font-light  flex flex-col items-center justify-center">
              <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link to="/" className="anchor-custom    ">
                  Home
                </Link>
              </div>
              <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link
                  to="/about"
                  className=" my-2 lg:my-4 font-extralight text-[12px] lg:text-[15px]  "
                >
                  About
                </Link>
              </div>
              <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link
                  to="/privacy"
                  className="my-2 lg:my-4 font-extralight text-[12px] lg:text-[15px]  "
                >
                  Privacy
                </Link>
              </div>
              <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link
                  to="/TermsAndCondition"
                  className="my-2 lg:my-4 font-extralight text-[12px] lg:text-[15px]   "
                >
                  Terms and Condition
                </Link>
              </div>
              <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link
                  to="/refund"
                  className="my-2 lg:my-4 font-extralight text-[12px] lg:text-[15px]  "
                >
                  Refunds
                </Link>
              </div>
              {/* <div className="lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                <Link
                  to="/"
                  className="my-2lg:my-4 font-extralight text-[12px] lg:text-[15px]  "
                >
                  Contact
                </Link>
              </div> */}
            </div>
          </div>
          <div className="space-y-4 lg:px-5 justify-center">
            <h1 className="mb-3 font-semibold text-[18px] text-center  ">
              TOP LOCATIONS
            </h1>
            <div className=" text-[12px] lg:text-[15px]  font-light  flex flex-col items-center justify-center">
              <div onClick={() => handleClick("Lonavala")} className="  cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                Lonavala
              </div>
              <div onClick={() => handleClick("Goa")} className=" cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
                Goa
              </div>
              <div onClick={() => handleClick("Alibag")} className=" cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
              Alibag
              </div>
              <div onClick={() => handleClick("Igatpuri")} className=" cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
              Igatpuri
              </div>
              <div onClick={() => handleClick("Mahabaleshwar")} className=" cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
              Mahabaleshwar
              </div>
              <div onClick={() => handleClick("Manali")} className=" cursor-pointer my-[1px] lg:my-2 font-extralight text-[12px] lg:text-[15px]  ">
              Manali
              </div>
            </div>
          </div>
          <div className="lg:space-y-4 px-5 justify-center">
            <h1 className="mb-3 font-semibold text-[18px] text-center">
              CONTACT US
            </h1>
            <h1 className=" text-[12px] text-center lg:text-[15px] leading-loose font-light ">
            Shop no 2, Hermes Manor, Near shopper’s stop Chembur, Mumbai, 400089, India
            </h1>
            <h1 className=" text-[12px] text-center lg:text-[15px] leading-loose font-light ">
              +91 747-729-8413
            </h1>
            <h1 className=" text-[12px] text-center lg:text-[15px] leading-loose font-light ">
              contact@a2zroomz.com
            </h1>
          </div>
        </div>
        <div className="flex flex-col md:hidden text-[#FFFFFF] pt-4  w-full">
          <div
            className="flex flex-col border-b-[1px] py-3 border-[#FFFFFF] px-3"
            onClick={() => {
              if (open === "1") setOpen("");
              else setOpen("1");
            }}
          >
            <div className="flex flex-row justify-between items-center w-full  ">
              <div className="text-[22px] ">About us</div>
              {open === "1" ? (
                <div className="">
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  {" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.439103 1.52475L0.504728 1.59771L5.46306 7.03457C5.63077 7.21869 5.88233 7.33333 6.16306 7.33333C6.44379 7.33333 6.69535 7.21522 6.86306 7.03457L11.8177 1.60813L11.9016 1.5178C11.9636 1.43095 12 1.32673 12 1.21556C12 0.913323 11.7302 0.666667 11.3948 0.666667H0.938582C0.603166 0.666667 0.333374 0.913323 0.333374 1.21556C0.333374 1.33021 0.373478 1.4379 0.439103 1.52475Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            {open === "1" && (
              <div className="my-2">
                A2Z Villaz is a company founded to provide experiences to
                travelers, which they can never forget. We want to work towards
                a painless traveling experience within India.
              </div>
            )}
          </div>
          <div
            className="flex flex-col border-b-[1px] py-3 border-[#FFFFFF] px-3"
            onClick={() => {
              if (open === "2") setOpen("");
              else setOpen("2");
            }}
          >
            <div className="flex flex-row justify-between items-center w-full my-2 ">
              <div className="text-[22px] ">Top Location</div>
              {open === "1" ? (
                <div className="">
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  {" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.439103 1.52475L0.504728 1.59771L5.46306 7.03457C5.63077 7.21869 5.88233 7.33333 6.16306 7.33333C6.44379 7.33333 6.69535 7.21522 6.86306 7.03457L11.8177 1.60813L11.9016 1.5178C11.9636 1.43095 12 1.32673 12 1.21556C12 0.913323 11.7302 0.666667 11.3948 0.666667H0.938582C0.603166 0.666667 0.333374 0.913323 0.333374 1.21556C0.333374 1.33021 0.373478 1.4379 0.439103 1.52475Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            {open === "2" && (
              <div className="">
                <div className=" text-[16px]  font-light  flex flex-col  justify-center">
                <div onClick={() => handleClick("Lonavala")} className="  cursor-pointer my-[3px]  text-center  ">
                Lonavala
              </div>
              <div onClick={() => handleClick("Goa")} className=" cursor-pointer my-[3px]  text-center  ">
                Goa
              </div>
              <div onClick={() => handleClick("Alibag")} className=" cursor-pointer my-[3px]  text-center  ">
              Alibag
              </div>
              <div onClick={() => handleClick("Igatpuri")} className=" cursor-pointer my-[3px]  text-center  ">
              Igatpuri
              </div>
              <div onClick={() => handleClick("Mahabaleshwar")} className=" cursor-pointer my-[3px]  text-center  ">
              Mahabaleshwar
              </div>
              <div onClick={() => handleClick("Manali")} className=" cursor-pointer my-[3px]  text-center  ">
              Manali
              </div>
                  
                </div>
              </div>
            )}
          </div>
          <div
            className="flex flex-col border-b-[1px] py-3 border-[#FFFFFF] px-3"
            onClick={() => {
              if (open === "3") setOpen("");
              else setOpen("3");
            }}
          >
            <div className="flex flex-row justify-between items-center w-full my-2 ">
              <div className="text-[22px] ">Navigational Links</div>
              {open === "3" ? (
                <div className="">
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  {" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.439103 1.52475L0.504728 1.59771L5.46306 7.03457C5.63077 7.21869 5.88233 7.33333 6.16306 7.33333C6.44379 7.33333 6.69535 7.21522 6.86306 7.03457L11.8177 1.60813L11.9016 1.5178C11.9636 1.43095 12 1.32673 12 1.21556C12 0.913323 11.7302 0.666667 11.3948 0.666667H0.938582C0.603166 0.666667 0.333374 0.913323 0.333374 1.21556C0.333374 1.33021 0.373478 1.4379 0.439103 1.52475Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            {open === "3" && (
              <div className="my-2">
                <div className="text-[16px]  font-light  flex flex-col  justify-center">
                  <div className="my-[3px]  text-center ">
                    <Link to="/" className="anchor-custom    ">
                      Home
                    </Link>
                  </div>
                  <div className="my-[3px]  text-center ">
                    <Link to="/" className="   ">
                      About
                    </Link>
                  </div>
                  <div className="my-[3px]  text-center ">
                    <Link to="/privacy" className="  ">
                      Privacy
                    </Link>
                  </div>
                  <div className="my-[3px]  text-center ">
                    <Link to="/TermsAndCondition" className="   ">
                      Terms & Condition
                    </Link>
                  </div>
                  <div className="my-[3px]  text-center ">
                    <Link to="/refund" className="  ">
                      Refunds
                    </Link>
                  </div>
                  {/* <div className="my-[3px]  text-center ">
                    <Link to="/" className="  ">
                      Contact
                    </Link>
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <div
            className="flex flex-col border-b-[1px] py-3 border-[#FFFFFF] px-3"
            onClick={() => {
              if (open === "4") setOpen("");
              else setOpen("4");
            }}
          >
            <div className="flex flex-row justify-between items-center w-full my-2 ">
              <div className="text-[22px] ">Contact us</div>
              {open === "4" ? (
                <div className="">
                  <svg
                    width="11"
                    height="7"
                    viewBox="0 0 11 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7162 5.86738L10.6564 5.79766L6.14082 0.601367C5.98809 0.425391 5.75898 0.315821 5.50332 0.315821C5.24766 0.315821 5.01855 0.428711 4.86582 0.601367L0.353516 5.7877L0.277149 5.87402C0.220703 5.95703 0.1875 6.05664 0.1875 6.16289C0.1875 6.45176 0.433204 6.6875 0.738672 6.6875L10.2613 6.6875C10.5668 6.6875 10.8125 6.45176 10.8125 6.16289C10.8125 6.05332 10.776 5.95039 10.7162 5.86738Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : (
                <div>
                  {" "}
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.439103 1.52475L0.504728 1.59771L5.46306 7.03457C5.63077 7.21869 5.88233 7.33333 6.16306 7.33333C6.44379 7.33333 6.69535 7.21522 6.86306 7.03457L11.8177 1.60813L11.9016 1.5178C11.9636 1.43095 12 1.32673 12 1.21556C12 0.913323 11.7302 0.666667 11.3948 0.666667H0.938582C0.603166 0.666667 0.333374 0.913323 0.333374 1.21556C0.333374 1.33021 0.373478 1.4379 0.439103 1.52475Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            {open === "4" && (
              <div className="my-2">
                <h1 className=" my-[3px] text-[15px]  text-center ">
                  a2zRoomz ,310 AKD Tower, Gurgaon, 122001 Haryana, India
                </h1>
                <h1 className=" my-[3px] text-[15px]  text-center ">
                  +91 894-856-3789
                </h1>
                <h1 className=" my-[3px] text-[15px]  text-center ">
                  contact@a2zroomz.com
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
